@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 10;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Mukta", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.file-input {
  display: inline-block;
  text-align: left;
  background: #ffffff;
  padding: 16px;
  width: 380px;
  position: relative;
  border-radius: 3px;
}

.file-input > [type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.file-input > .button {
  display: inline-block;
  cursor: pointer;
  background: #eee;
  padding: 8px 16px;
  border-radius: 2px;
  margin-right: 8px;
  background: rgb(30 58 138);
  color: white;
}

/* .file-input:hover > .button {
  background: rgb(30 58 138);
  color: white;
} */

.file-input > .label {
  color: #000000;
  white-space: nowrap;
  /* opacity: .3; */
}

.file-input.-chosen > .label {
  /* opacity: 1; */
}

@media screen and (max-width: 457px) {
  .file-input {
    width: 350px;
  }
}
